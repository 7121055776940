/* --------------- @Dashboard Area ---------------- */
$mainDarkBlue: #03256c;
$primaryWhite: #ffffff;

body {
  background: #F8FAFC;
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.edit-profile-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
}

.customize-card {
  background: $primaryWhite !important;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.edit-profile-editImage {
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #fff;
  color: $mainDarkBlue;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 5px;

  i {
    font-size: 16px;
  }
}

.dashboard-container {
  // background-color: $backgrounColor;
  height: 100%;
  overflow: auto;
  // padding: 1rem;
  display: flex;

  .dashboard-box {

    background: #F8FAFB;
    border-radius: 20px;
    padding: 15px;
    min-height: 150px;

    .dashboard-icon {
      font-size: 50px;
      color: $primaryRed;
    }

    .dash-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .count {
        font-size: 50px;
        color: $primaryRed;
      }

      .title {
        color: $darkTextColor;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: right;

      }
    }

  }


  // .custom-table.p-datatable .p-datatable-wrapper{
  //   height: auto !important;
  // }
}

.dashboard-right-side {
  width: calc(100% - 5rem);

  &.large-dashboard-area {
    width: 100%;

    .sidebar-handler {
      left: 0;
    }

    .dashboard-header {
      transition: 1s;
    }

    .dashboard-body {
      transition: 1s;
    }
  }
}

.card-title {
  .header-title {
    color: $lightTextColor;
    font-size: 20px;
    font-weight: 600;
    display: block;
  }

  .path-name {
    color: $lightTextColor;
    font-size: 15px;
    font-weight: 400;
  }

  .searchbar-area {
    position: relative;

    input {
      padding: 0px 10px !important;
      height: 35px;
      border: none !important;
      background-color: $lightBtnColor;
      margin-right: 0px !important;
      max-width: 220px;
    }

    .icon-search {
      position: absolute;
      right: 11px;
      top: 5px;
      font-size: 17px;
    }
  }
}

/* --------------- @Sidebar Area ---------------- */

.sidebar-container {
  // width: 18rem;
  // height: 100vh;
  // overflow: auto;
  // transition: 1s;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // overflow: hidden;

  width: 80px;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
  white-space: nowrap;

  .sidebar-footer-text {
    white-space: nowrap;
  }

  .info-icon {
    display: none;
  }

  &.sidebar-mini {
    width: 60px;
    overflow: hidden;

    .parent-sidebar-content {
      margin-inline: 0px !important;

      &:hover,
      &.active {
        margin-left: 6px !important;
        padding-left: 5px !important;
        // background-color: $darkBlueColor;
      }
    }

    .sidebar-logo img {
      width: unset;
    }

    .info-icon {
      display: block;
    }

    .sidebar-footer-text {
      display: none;
      white-space: nowrap;
    }

    .sidebar-head {
      &::after {
        width: 0px;
        transition: 1s;
      }
    }

    &:hover {
      width: 25%;

      .parent-sidebar-content {
        margin-inline: 25px !important;
      }

      .info-icon {
        display: none;
      }

      .sidebar-footer-text {
        display: block;
      }

      &+.large-dashboard-area {

        // width: calc(100vw - 270px) !important;

        // .dashboard-header {
        //   width: calc(100vw - 270px) !important;
        // }

        // .dashboard-body {
        //   width: calc(100vw - 300px) !important;
        // }
      }
    }
  }
}

.sidebar-container.expanded {
  width: 250px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 80px;
  // background-color: $lighPrimaryColor;
  // padding: 10px 8px 10px 8px;
  position: relative;
  border-bottom-right-radius: 0.5rem;
  background: transparent;
  // margin: 0 0.5rem 0.5rem 0;
  margin: 0.30rem 0rem;
  padding-top: 0.40rem;
  // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  // height: 65px;
}

.sidebar-header2 {
  width: 100% !important;
  height: 4.05rem !important;
  background-color: #f3f3f3 !important;
  margin: 0 auto !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  white-space: nowrap;
}

.sidebar-handler {
  background-color: #22B24C;
  position: absolute;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 51px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #ffffff;
  }
}

.sidebar-close {
  display: none;
}

.sidebar-logo {
  width: 80%;
  // margin: auto;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.sidebar-header .sidebar-logo img {
  width: 100%;
  max-width: 60px;
}

.sidebar-container.expanded .sidebar-header .sidebar-logo img {
  max-width: 100px;
  /* Adjusted for expanded size */
}

.sidebar-body {
  // position: relative;
  // overflow: auto;
  // padding-top: 0.75rem;
  background-color: #1f2f3e;
  // height: calc(100vh - 102px);
  border-radius: 0.5rem;
  // margin-top: 0.5rem;

  // .parent-sidebar-options {
  //   list-style: none;
  //   padding: 0px !important;
  //   margin: 0px !important;
  //   transition: all 0.3s ease-in;
  //   height: calc(100% - 0px) !important;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  //   color: $sidebarTextColor;
  //   // Scroll on hover
  //   // mask-image: linear-gradient(to top, transparent, black),
  //   //   linear-gradient(to left, transparent 7px, black 7px);
  //   // mask-size: 100% 20000px;
  //   // mask-position: left bottom;
  //   // -webkit-mask-image: linear-gradient(to top, transparent, black),
  //   //   linear-gradient(to left, transparent 7px, black 7px);
  //   // -webkit-mask-size: 100% 20000px;
  //   // -webkit-mask-position: left bottom;
  //   // transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  //   // &:hover {
  //   //   -webkit-mask-position: left top;
  //   // }

  //   @keyframes background {
  //     from {
  //       background: pink;
  //     }

  //     to {
  //       background: #c0d6ff;
  //     }
  //   }

  //   &>li {
  //     span {
  //       // padding-left: 20px;
  //       // white-space: nowrap;
  //       color: rgba(255, 255, 255, 0.50);

  //       font-size: 1rem;
  //       font-style: normal;
  //       font-weight: 500;
  //       line-height: 1.5rem;
  //       /* 150% */
  //     }
  //   }

  //   .parent-sidebar-option {
  //     width: 100%;
  //     min-height: 46px !important;
  //     // border-radius: 6px;
  //     display: flex;
  //     align-items: center;
  //     margin-bottom: 10px;
  //     cursor: pointer;
  //     transition: all 0.3s ease-in;
  //     border-left: solid 4px transparent;

  //     &.isParentActive {
  //       min-height: 300px;
  //       transition: all 0.3s ease-in;

  //       .parent-sidebar-content {
  //         padding: 0.75rem 1.5rem;
  //         color: $whiteColor;
  //         // background-color: $lighPrimaryColor;
  //         // // margin-inline: 20px;
  //         // border-radius: 8px;
  //         border-radius: 0rem;
  //         background: rgba(101, 144, 203, 0.6);
  //         // background-color: $darkBlueColor;

  //         span {
  //           color: #fff;
  //         }
  //       }
  //     }

  //     .parent-sidebar-content {
  //       // font-size: 16px;
  //       // font-weight: 400;
  //       // letter-spacing: 0.04em;
  //       // text-transform: uppercase;
  //       // padding: 15px 10px;
  //       padding: 0 1.5rem;
  //       // margin-inline: 25px;
  //       border-radius: 8px;
  //       transition: all 0.3s ease-in;

  //       &:hover,
  //       &.active {
  //         padding: 0.75rem 1.5rem;
  //         color: $whiteColor;
  //         // background-color: $lighPrimaryColor;
  //         // // margin-inline: 20px;
  //         // border-radius: 8px;
  //         border-radius: 0rem;
  //         background: rgba(101, 144, 203, 0.3);
  //         // background-color: $darkBlueColor;

  //         span {
  //           color: #fff;
  //         }
  //       }
  //     }

  //     &.active {
  //       // background: rgba(101,144,203, 0.3);
  //       // background-color: $darkBlueColor;
  //       border-left: solid 4px #6590CB;
  //       color: $whiteColor;
  //     }

  //     &.solo-active {
  //       // background-color: transparent;
  //       // color: $whiteColor;
  //       border-left: solid 4px #6590CB;
  //     }

  //     .sidebar-icon {
  //       // width: 35px;
  //       margin-right: 1.25rem;
  //       text-align: center;
  //       font-size: 1.125rem;
  //     }

  //     .sidebar-tail-icon {
  //       // width: 30px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       transform: rotate(90deg);
  //       // transition-delay: 0.5;
  //       transition: all 0.3s ease-out;

  //       &.active {
  //         transform: rotate(0deg);
  //       }
  //     }
  //   }
  // }

  // .child-sidebar-options {
  //   list-style: none;
  //   padding: 0px !important;
  //   // margin: 0px 6px !important;
  //   margin-top: 0.5rem;
  //   width: 100%;
  //   transition: all 0.3s ease-in;

  //   .child-sidebar-option {
  //     width: 100%;
  //     min-height: 46px;
  //     // border-radius: 6px;
  //     display: flex;
  //     align-items: center;
  //     color: $sidebarTextColor;
  //     // justify-content: center;
  //     // font-size: 16px;
  //     // font-weight: 400;
  //     // text-transform: uppercase;
  //     padding: 0 1.5rem 0 3.9rem;
  //     // padding-left: 6px;
  //     transition: all 0.3s ease-in;
  //     transition-duration: 300ms;
  //     cursor: pointer;

  //     &:hover,
  //     &.active {
  //       padding: 0.75rem 1.5rem 0.75rem 3.9rem;
  //       color: $whiteColor;
  //       // background-color: $lighPrimaryColor;
  //       // // margin-inline: 20px;
  //       // border-radius: 8px;
  //       border-radius: 0rem;
  //       background: rgba(101, 144, 203, 0.3);

  //       span {
  //         color: #fff;
  //       }
  //     }

  //     .sidebar-icon {
  //       // width: 35px;
  //       margin-right: 1.25rem;
  //       text-align: center;
  //       font-size: 1.125rem;
  //     }

  //     // &:hover {
  //     //   color: $whiteColor;
  //     //   background-color: $lighPrimaryColor;
  //     //   // background-color: $darkBlueColor;
  //     // }

  //     // &:last-child{
  //     //     margin-bottom: 10px;
  //     // }
  //   }
  // }
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.sidebar-footer {
  // display: none;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  position: relative;
  // color: $whiteColor;
  border-top: 1px solid #6590CB2f;
  text-align: center;
  font-size: 14px;
  margin-top: auto;
}

/* --------------- @Header Area ---------------- */

.dashboard-header {
  // width: calc(100vw - 270px);
  // background-color: $whiteColor;
  // border-radius: 0px;
  // padding: 10px 20px 0 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: transparent;
  // margin: 0 0.5rem 0.5rem;
  padding: 0.62rem;
  // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  // margin-bottom: 10px;
  // box-shadow: 0px 8px 8px rgba(149, 157, 165, 0.1);
  position: relative;
  padding-left: 0px;

  .header-logo {

    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    img {
      width: 11.64281rem;
      height: 2.5rem;
    }
  }

  .input-box {
    border: none;

  }
}

.main-top-bar {
  background-color: #f3f3f3;
  padding-inline: 15px;
  border-radius: 10px;
  position: static;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-divider.p-divider-horizontal {
  margin: 1.06rem 0rem 0rem auto;
  width: 98%;
}

.header-right-action {
  display: flex;
  align-items: center;
  justify-content: center;

  .user-details {
    margin-right: 0.94rem;

    .user-name {
      //font-family: 'Inter';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      margin: 0px;
    }

    .user-time {
      //font-family: 'Inter';
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.875rem;
      text-align: right;
      margin: 0px;
    }
  }
}

.profile-section {
  // margin-left: 10px;


  .profile-img {
    width: 45px;
    height: 45px;
    position: relative;

    .userActive {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      bottom: 2px;
      right: 0px;
      background-color: $lightGreenColor;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
    }
  }

  .user-detail {
    margin-left: 14px;

    label {
      font-size: 18px;
      font-weight: 500;
      color: $darkTextColor;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: $lightTextColor;
    }

    .userType {
      border-radius: 4px;
      padding: 7px 14px;
      background-color: $lightYellowColor;
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.icon-box {
  margin-left: 0.62rem;
}

/* --------------- @Component common Area ---------------- */

.userType {
  margin-top: 5px;
  width: 100%;
  font-size: 12px;
  color: $lightTextColor;
  border-radius: 4px;
  padding: 2px 14px;
  background-color: $lightYellowColor;
  font-weight: 400;
  border-radius: 25px;
}


.project-btn {
  width: 100%;
  font-size: 14px;
  color: $lightTextColor;
  border-radius: 4px;
  padding: 7px 14px;
  background-color: $lightYellowColor;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
}

.dashboard-body {
  // width: calc(100vw - 300px);
  // background-color: #F8FAFC;
  // border: 1px solid #E7E7E7;
  // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  // border-radius: 10px 0 0 0;
  // padding: 10px 2rem;
  // margin: 15px;
  // box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  height: calc(100vh - 85px);
  overflow: auto;

  // margin: 0.5rem 0.5rem 0.5rem;
  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dashboard-content {
      p {
        margin: 0rem;
      }

      .dashboard-title {
        //font-family: 'Inter';
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem;
        color: #212B36;
      }

      .dashboard-sub-title {
        //font-family: 'Inter';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        color: #637381;
      }

    }

    .icon-box {
      width: 3.0625rem;
      height: 3.0625rem;
      border-radius: 50%;
      border-radius: 3.125rem;
      border: 0.5px solid #E7E7E7;
      background: #F8FAFC;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .inner-body {
    height: 76vh;
    border-radius: 0.5rem;
    border: 1px solid #E7E7E7;
    background: #FFF;
    margin-top: 1.69rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-text {
      //font-family: 'Inter';
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.875rem;
      color: #212B36;
      margin: 0px;
      text-align: center;
      margin-top: 1.48rem;

    }

    .inner-sub-text {
      width: 21.75rem;
      //font-family: 'Inter';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      color: #637381;
      margin: 0px;
      text-align: center;
      margin-top: 0.94rem;
    }

    .btn-box {
      width: 8.75rem;
      height: 2.625rem;
      border-radius: 1.875rem;
      background-color: #002145;
      color: #FFF;
      //font-family: 'Inter';
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.44rem;
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  .card-title {
    font-size: 20px;
    font-weight: 500;
    color: $lightTextColor;
  }

  .card-actions {
    display: flex;
    align-items: center;

    .btn-icon-action {
      margin-left: 8px;
    }
  }
}

.card-body {
  padding: 10px;
}

.profile-img-popup {
  width: 60px;
  height: 60px;

  img {
    border-radius: 50px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dashboard-box .custom-table.p-datatable .p-datatable-wrapper {
  height: auto !important;
  min-height: auto !important;
}

.dashboard-lable h3 {
  margin: 0;
  padding: 0 10px;
}

.custom-dialog-content {
  padding-inline: 1.75rem;

  .dialog-content-title {
    color: #637381;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    margin: 0;
    padding-bottom: 1.8rem;
  }

  .import-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.18rem;
  }

  .import-source {
    border-radius: 0.9375rem;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Shadow 3 */
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
    width: 8.4375rem;
    height: 5.625rem;
    flex-shrink: 0;
    cursor: pointer;
    margin: auto;
    margin-bottom: 20px;

    &.active {
      border-radius: 0.9375rem;
      border: 1px solid #00B078;
      background: rgba(0, 176, 120, 0.10);
      box-shadow: none;
    }
  }

}

.imported-datasource-list {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 1.44rem;
  // justify-content: space-between;
  padding-top: 1.5rem;
}

.imported-datasource {
  border-radius: 0.5625rem;
  border: 1px solid #E7E7E7;
  padding: 0.75rem 1.25rem 0.75rem 0;

  .source-type {
    // width: 8.3125rem;
    // height: 3.625rem;
    // flex-shrink: 0;
    // border-radius: 0rem 0.5625rem 0.5625rem 0rem;
    // background: rgba(0, 176, 120, 0.10);
    padding: 0.56rem 0.75rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.625rem;
    flex-shrink: 0;
    border-radius: 0rem 0.5625rem 0.5625rem 0rem;
    background: rgba(0, 176, 120, 0.1);
  }

  .source-info {

    .source-name {
      color: #212B36;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      margin: 0;
    }

    .source-data {
      color: #637381;

      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      margin: 0;
    }
  }

  .source-action {
    color: #FFF;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    display: inline-flex;
    padding: 0.3125rem 0.875rem;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 3.125rem;
    background: #002145;

    &:hover {
      background-color: #003d80;
    }
  }

}

.card {
  background: var(--surface-card);
  padding: 2.25rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  border-radius: var(--border-radius);
}

.shadow-1 {
  box-shadow: 0 3px 5px rgba(0, 0, 0, .02), 0 0 2px rgba(0, 0, 0, .05), 0 1px 4px rgba(0, 0, 0, .08) !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex {
  display: flex !important;
}