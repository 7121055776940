@media only screen and (max-width: 575px) {
    
        // .login-container form {
        //     width: 90%;
        // }

        .login-container{
            margin-block: 25px;
            padding-inline: 27px !important;
            overflow-x: hidden;
        }

        .p-paginator-current {
            display: none;
        }



        .select-btn-box{
            justify-content: center;
        }
    }