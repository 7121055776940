* {
    font-family: Nunito Sans, sans-serif;

}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #B3B1BD;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
    background: #504973;
}

input,
button,
textarea {
    box-shadow: none !important;
    outline: none !important;
}

button {
    cursor: pointer !important;
}

.p-w-100 {
    width: 100% !important;
}

.p-h-100 {
    height: 100% !important;
}

.p-h-full {
    height: 100vh !important;
}

.p-position-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.text-white {
    color: $whiteColor !important;
}

.text-dark {
    color: $darkTextColor;
}

.text-13 {
    font-size: 13px;
}

.text-14 {
    font-size: 14px;
}

.text-15 {
    font-size: 15px;
}

.text-16 {
    font-size: 16px;
}

.text-17 {
    font-size: 17px;
}

.text-18 {
    font-size: 18px;
}

.text-19 {
    font-size: 18px;
}

.text-20 {
    font-size: 20px;
}

.text-22 {
    font-size: 22px;
}

.text-24 {
    font-size: 24px;
}

.text-30 {
    font-size: 30px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.icon-dot {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    top: 5px;
    right: 4px;
    background-color: $lightGreenColor;
    border: 2px solid $whiteColor;
}

.p-formgroup {
    label {
        display: block;
        color: $labelColor;
        font-size: 13px;
        margin-bottom: 8px;
    }

    span {
        color: $darkTextColor;
        font-size: 15px;
    }
}

.error-msg-alert {
    background-color: $dangerLightColor;
    padding: 15px;
    border-radius: 10px;

    i {
        font-size: 21px;
    }

    i,
    span {
        color: $dangerColor
    }
}

.green-text {
    color: $lightGreenColor !important;
}

.red-text {
    color: $dangerColor !important;
}

.form-container-area {
    height: 100%;
    overflow: auto;
}

.p-overflow-auto {
    overflow: auto;
}

.form-header {
    padding: 12px;

    i,
    label {
        font-size: 18px;
        font-weight: 500;
        color: $primaryColor;
    }
}

.form-field-header {
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
}

.header-input {
    width: 160px;
    margin-right: 8px;
    margin-top: 0px;

    .p-dropdown {
        margin-top: 0px;

        .p-dropdown-trigger {
            width: 2rem;
        }
    }

}

.dialog-popup1 {
    border-radius: 12px;
    overflow: auto;
    width: 25vw;

    @media only screen and (max-width: 576px) {
        width: 90vw;
    }

    .p-dialog-header {
        padding: 1.4rem;

        .p-dialog-title {
            font-size: 18px;
            font-weight: 500;
            color: $lightTextColor;
        }
    }

    .p-dialog-content {
        padding: 0 1.4rem 1.4rem !important;

        span {
            font-weight: 400;
            color: $lightTextColor;

            b {
                font-weight: 500;
            }
        }
    }

    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 2rem;

        .btn-dialog-delete,
        .btn-dialog-submit {
            margin-left: 8px;
        }
    }
}

.container-body {
    height: calc(100% - 35px);
    overflow: auto;
}

.profile-img {
    width: 100%;
    height: 450px;

    img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.custom-select {
    width: 225px;
}

.p-dialog-header {
    border: none !important;
}

.wayment-owner-dashboard {
    background: #fff;
    border-radius: 10px;
    height: calc(100vh - 147px);
    padding-inline: 1rem;
    overflow: auto;


    .heading-area {
        i {
            font-size: 1rem;
            color: #4ac3f6;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: .35rem;
        }

        h2 {
            color: $primaryColor;
            font-size: 1.25rem;
            font-family: "Poppins";
            font-weight: 500;
        }
    }

    .wb-counter-box {
        background-color: #fcfeff;
        border: 1px solid #e5e5e5;
        border-radius: .5rem;
        padding: 1rem;
        margin-block: 1rem;
        height: 120px;

        i {
            font-size: 1.25rem;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 0.5rem;
            color: #4ac3f6;
        }

        .title {
            color: #202124;
            font-size: 1rem;
            font-family: "Poppins";
            font-weight: 400;
            margin: 0;
        }

        .job-d-badge {
            border: 1px solid rgba(13, 148, 136, .2);
            background: #f0fdfa;
            display: inline-flex;
            border-radius: .375rem;

            p {
                font-family: "Poppins";
                font-weight: 400;
                font-size: .875rem !important;
                line-height: 1rem;
                color: #0f766e !important;
                margin: 0;
                padding: .35rem .5rem;
            }
        }

    }

    .searchbar-area {
        height: 35px;
        border-radius: 6px;
        border: 1px solid #03256c !important;
        background: transparent;
        display: flex;
        align-items: center;
        margin-left: 8px !important;
        position: relative;
        margin-right: 0.5rem;
        max-width: 580px;

        input {
            border: none;
            height: 35px;
            background-color: transparent;
        }

        .p-multiselect {
            border: none;
            height: 35px;
            background-color: transparent;
            max-width: 500px;
        }

        i {
            position: absolute;
            top: 0.6rem;
            right: 0rem;
            color: #4ac3f6;
            font-size: 1rem;
        }
    }
}

.e-weighment-dashoard {
    height: calc(100vh - 102px);
}

.e-btn {
    height: 36px;
    padding-inline: 10px;
    min-width: unset !important;
    background-color: #4ac3f6 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    color: #fff;

    i {
        color: #FFFFFF !important;
    }
}

.e-sction-btn {
    height: 35px;
    min-width: unset !important;
    background-color: #1d6c8d !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    color: #fff;

    i {
        color: #FFFFFF !important;
    }

    &:hover {
        background-color: #4e8ba5 !important;
    }
}

.e-border-btn {
    height: 35px;
    min-width: unset !important;
    background-color: #FFFFFF;
    border: 1px solid #1d6c8d !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 500;
    border-radius: 6px !important;
    cursor: pointer;
    color: #1d6c8d;
}

.color-white {
    color: #FFFFFF !important;
}

.id-card-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 28rem;
}

.dialog-footer1 {
    margin-inline: -21px;
    margin-bottom: -22px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .21) !important;
    padding: 15px !important;

    .primary-btn1 {
        height: 35px !important;
        min-height: 35px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 12px !important;
        min-width: -moz-fit-content !important;
        min-width: fit-content !important;
        line-height: 1 !important;
        outline: none !important;
        font-size: 14px;

        transition: 0s !important;
        background-color: transparent;
        color: #202124;
        border-color: #06bee1;
        margin-right: 0.5rem;
    }

    .primary-btn {
        background: #06bee1 !important;
        border-radius: 4px !important;
        padding: 4px 30px !important;
        display: inline-block !important;
        color: #fff !important;
        border: none;
        height: 35px !important;
        margin: 0px;
        margin-left: 0rem;
    }

    .e-pdf-btn {
        font-size: 14px;

        transition: 0s !important;
        background-color: transparent;
        color: #202124;
        border-color: #06bee1;
        border-radius: 6px;
    }
}

.e-logo-box {
    width: 100%;
    height: auto;

    img {
        width: 100%;
    }
}

.main-body {
    display: flex;
    // justify-content: space-between;
    padding-inline: 2rem;
    // width: 200px;
    // height: 200px;
    background-color: #06bee1;

    // width: 100%;
    // margin: 0px;
    // padding: 0px;
    // box-sizing: border-box;
    // position: relative;

    .id-card-pdf {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 2rem;

        h1 {
            font-size: 0.5rem;
            font-style: normal;
            font-weight: 600;
            margin: 0px;
            color: #074E69;
        }

        hr {
            margin: 0px;
            color: #7a7878;
            margin-block: 0.5rem;
        }

        .id-card-main {
            padding: 0.5rem 0.5rem;

            .Info-box {
                background-color: #FFFFFF;
                border-radius: 6px;
                position: relative;
                opacity: 1;
                margin-top: 3px;


                .standerd-text {
                    font-size: 0.40rem;
                    font-style: normal;
                    font-weight: 600;
                    color: #064D69;
                    margin: 0px;
                    position: absolute;

                    right: 4%;
                }

                .background {
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                    background-color: transparent;


                    position: absolute;
                    top: 50px;
                    left: 12px;

                    img {
                        width: 10rem;
                        height: 10rem;
                        border-radius: 50%;
                        opacity: 0.1;
                    }

                }


            }

            .header-logo {
                width: 100%;
                max-height: 500px;
                height: 50px;
                background-color: #e2e379;
                display: flex;
                justify-content: center;

                .logo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .current-year {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 0.3rem;

                .year-text {
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 700;
                    margin: 0px;
                    color: #074E69;
                }
            }

            .profile-box {
                width: 50px;
                height: 50px;
                background-color: #FFFFFF;

                margin: 0.3rem auto 0.3rem auto;
                display: flex;
                align-items: center;
                justify-content: center;

                .profile {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;


                    image-resolution: 300dpi;
                }
            }

            .name {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding-block: 0.5rem;


                .name-text {
                    font-size: 0.5rem;
                    font-style: normal;
                    font-weight: 600;

                    margin: 0px;
                    text-transform: capitalize;
                }
            }

            .info-main {
                .inner-info {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;


                    .inner-info-text {
                        font-size: 0.5rem;
                        font-style: normal;
                        font-weight: 700;
                        color: #064D69;
                        margin: 0px;
                        text-transform: capitalize;
                        max-width: 70px;
                        width: 40px;
                    }

                    .inner-info-subtext {
                        font-size: 0.5rem;
                        font-style: normal;
                        font-weight: 400;
                        color: #064D69;
                        margin: 0px;
                        text-transform: capitalize;
                        max-width: 100px;
                        width: 86px;
                        display: flex !important;
                        align-items: baseline !important;
                        gap: 5px;
                    }
                }
            }

            .p-signature {
                display: flex;
                flex-direction: column;
                align-items: end;


                .footer-sign {
                    width: 30px;
                    height: 20px;
                    object-fit: contain;
                    margin-right: 10px;
                }
            }

            .inner-info-text {
                font-size: 0.5rem;
                font-style: normal;
                font-weight: 700;
                color: #064D69;
                margin: 0px;
                text-transform: capitalize;
                width: 70px;
            }

            .inner-info-subtext {
                font-size: 0.5rem;
                font-style: normal;
                font-weight: 400;
                color: #064D69;
                margin: 0px;
                text-transform: capitalize;
                width: 222px;
            }

            .footer-text-box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.15rem;

                .footer-text {
                    font-size: 0.52rem;
                    font-style: normal;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin: 0px;
                }
            }



        }
    }
}

.e-field-checkbox {
    max-width: 500px;
    width: 200px;
}

.e-profile-header {
    .e-profile-box {
        background-color: #FFFFFF;
        padding: 0.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: flex-start;
        max-width: 40rem;

        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
        margin-top: 1rem;

        .e-profile-main {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 15rem;
        }

        .e-profile {
            height: 15rem;
            width: 10rem;
            object-fit: fill;
        }

        .e-profile-info {
            margin-left: 1rem;
            height: 16rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .profile-text-box {
                background-color: #e5e5e5;
                padding: 0.5rem 0.75rem;
                border-radius: 0.5rem;


                .profile-text {
                    margin: 0px;
                    font-size: 0.87rem;
                    color: #6b6565;
                    font-weight: 600;
                }
            }

            .profile-cv-box {
                background-color: #FBE6E8;
                padding: 0.5rem 0.75rem;
                border-radius: 0.25rem;
                width: 8rem;

                .profile-cv-text {
                    font-size: 0.87rem;
                    color: #EE6171;
                    font-weight: 600;
                }

                i {
                    color: #EE6171 !important;
                    cursor: pointer;
                }
            }

            .profile-transport-box {
                border: 1px solid #E5E5E5;
                background-color: #FFFFFF;
                padding: 0.4rem 0.75rem;
                border-radius: 0.25rem;
                margin-inline: 0.5rem;

                .profile-t-text {
                    font-size: 0.87rem;
                    color: #B7B6CE;
                    font-weight: 600;
                }

            }
        }
    }
}

.input-height {
    height: 2.6rem;
}

.e-searchbar-area {
    i {
        top: 0px !important;
    }
}

.e-student-table {
    .e-student-list {
        display: flex;
        justify-content: flex-start;

        .student-h {
            max-width: 400px;
            text-align: left;
        }

        .student-text {
            max-width: 400px;
            text-align: left;
        }
    }
}

.e-custom-inputs {
    i {
        top: 5px !important;
    }
}

.e-import-input {
    .p-fileupload {
        .p-fileupload-buttonbar {
            padding: 0px;
            border: none;
            background: transparent;
        }

        .p-fileupload-content {
            display: none;
        }
    }
}

.e-content-box {
    .e-title {
        color: #1c515e;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        letter-spacing: .04375rem;
        margin: 0;
        margin-block: 1.25rem;
        text-align: center;
    }

    .e-sub-title {
        color: #1c515e;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.025rem;
        margin: 0;
        max-width: 23.75rem;
        text-align: center;
    }
}

.idCardContent {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 50px;
    margin-block: 0px;
    padding: 40px 40px;
    // margin-inline: 5px;
}

@media print {
    @page {
        size: var(--page-size);
    }

    .idCardContent {
        min-width: 100%;
    }
}

#pdf-content {
    width: 800px;

    height: auto;
    background-color: #fff;

}

.e-main-body {
    margin-block: 1rem;
}

// span.p-button.p-component.p-fileupload-choose {
//     color: white;
// }


.wayment-pdf-container {
    width: 250px;
    max-width: 250px;
    display: flex;
}

.time-table {
    .card-actions {
        background: #fff;
        border-radius: 8px;
        padding: 16px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        text-align: center;

        .p-card-body {
            .p-card-title {
                display: flex;
                justify-content: left;
            }

            .p-card-content {
                padding-top: 0px;
            }
        }

        .card-header {
            .card-header-left {
                input {
                    height: 36px;
                }
            }
        }
    }

}

.time-table-input {
    input.p-inputtext {
        width: 180px;
    }
}

.e-class-time-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 180px);

}

.custom-dialog .p-dialog-header {
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.custom-dialog .p-dialog {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.slideshowContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.idPreviewCardContent {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
    width: 100%;
    padding: 20px;
}

.slideshowControls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    padding: 0 20px;
}

.control-btn {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 12px;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.control-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.dialog-footer1 {
    margin-top: 20px;
    padding: 10px 0;
}

.custom-id-dialog {
    width: 30rem;

    .p-dialog-header,
    .p-dialog-content {
        padding: 1rem 1.05rem;
    }

    .p-dialog-header {
        border-bottom: 1px solid rgba(6, 77, 105, 0.3) !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .p-dialog-content {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-bottom: 0.75rem;
    }

    .custom-id-dialog-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(6, 77, 105, 0.3) !important;
        margin-inline: -1.05rem;
        padding-inline: 1.05rem;
        padding-top: 0.5rem;

        .footer-btn {
            background-color: #06bee1;
            color: white;
            border: none;
            padding: 0.5rem 0.75rem;
            border-radius: 5px;
            font-size: 14px;
            transition: background-color 0.3s;
            margin: 0;

            &:hover {
                background-color: #09839b;
            }
        }
    }

    .custom-id-preview {
        display: flex;
        justify-content: center;

        div:first-child {
            flex: none !important;
        }

        ~.slideshowControls {
            .control-btn {
                padding: 8px;
                font-size: 6px;
            }
        }
    }

}

.e-pdf-btn {
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s;
}

.e-pdf-btn:hover {
    background-color: #0056b3;
}

.primary-btn1 {
    background-color: #FF5722;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.primary-btn1:hover {
    background-color: #D84315;
}

.custom-calendar .p-inputtext {
    border-radius: 5px 0 0 5px;
    border: 1px solid #6c757d;
}

.custom-calendar .p-inputtext:focus {
    box-shadow: none;
}

.custom-calendar .p-datepicker-trigger {
    background-color: #4fc3f7;
    color: #ffffff;
    border-radius: 0 5px 5px 0;
    border: 1px solid #6c757d;
}

.custom-calendar .p-datepicker-trigger:hover {
    background-color: #42a5f5;
}

.p-d-flex {
    display: flex;
}

.p-ai-center {
    align-items: center;
}

.p-jc-between {
    justify-content: space-between;
}

.small-file-upload .p-button {
    padding: 5px 12px !important;
    font-size: 12px !important;
    height: 35px !important;
    line-height: 1 !important;
}

.small-file-upload-container {
    display: flex;
    align-items: center;
    height: 50px;
}



@media (min-width: 991px) {
    .p-w-md-auto {
        width: auto !important;
    }
}