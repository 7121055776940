.template-config-container {
    padding: 2rem;
    width: 700px;
    margin: 0 auto;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #003366;
}

.layout-settings,
.title-settings,
.header-section {
    margin-bottom: 1rem;
}

.orientation-radio-buttons,
.apply-setting-to-radio {
    display: flex;
    gap: 10px;
    margin-top: 0.5rem;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

input[type="file"] {
    display: none;
}

.file-upload-container {
    display: flex;
    align-items: center;
}

button.p-button {
    display: block;
    margin: 1rem auto;
}

.color-picker {
    margin-bottom: 1rem;
}

.title-settings .p-field-radiobutton {
    display: inline-flex;
    align-items: center;
    margin-right: 1rem;
}

.title-settings label {
    font-weight: bold; 
}

.p-field-radiobutton label {
    margin-left: 0.5rem;
}
