.notification-general-admin {
    max-width: 20rem;
    max-height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;

    .p-overlaypanel-content {
        padding: 0.75rem 0.5rem;
    }

    .noti-drop-container {
        gap: 0.5rem;
        width: 100%;

        .notification-number {
            height: 1.5rem;
            width: 1.5rem;
            background-color: #03256C;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1rem;
            color: #e5e5e5;
            text-align: center;
            font-size: 0.6875rem;
            font-style: normal;
            font-weight: 700;
        }

        .notification-info {
            color: #03256C;
            text-align: center;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
        }

        .noti-input {
            width: 80%;

            .p-dropdown-label {
                padding-block: 0.5rem;
            }
        }
    }

    .notification-area {
        list-style: none;
        padding: 1rem 0 0 0;
        margin: 0 0 -0.75rem 0;

        .notification-details {
            // margin-top: 0.5rem;
            padding: 0.5rem 0.75rem;
            margin-inline: -0.75rem;
            border-bottom: 1px solid #e5e5e5;

            .notification-each-text {
                font-size: 0.8125rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: #506690;
            }

            .notification-each-date {
                padding-top: 0.35rem;
                color: rgba(80, 102, 144, 0.90);
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: end;
            }
        }
    }
.noti-info-dot{
    width: 0.5rem;
    height: 0.5rem;
    background-color: #06BEE1;
    border-radius: 50%;
    margin-left: 0.35rem;
    margin-right: 0.25rem;
}
}

.notification-btn {
    height: 2rem !important;
    width: 2rem !important;
    font-size: 0.5rem;
    padding: 0;
    background-color: rgba(80, 102, 144, 0.1) !important;
    color: #506690;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem !important;

    &:hover {
        background-color: #03256C !important;
        color: #fff;
    }
}