.auth-container {
    height: 100vh;
    overflow: hidden;

    .login-background {
        // background-color: #002145;
        object-fit: cover;
        width: 100%;
        background: #54B5c2;
        /* fallback colour. Make sure this is just one solid colour. */
        background: -webkit-linear-gradient(rgba(66, 74, 101, 0.8), rgba(101, 144, 203, 0.8)), url(../images/4122.jpg);
        background: linear-gradient(rgba(66, 74, 101, 0.8), rgba(101, 144, 203, 0.8)), url(../images/4122.jpg);
        /* The least supported option. */
        background-position: bottom left;
        background-repeat: no-repeat;
    }

    .login-bg-box {
        position: absolute;
        bottom: 0;
        width: 100%;

        .login-bg-text {
            display: flex;
            height: 100vh;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
        }

        h1 {
            color: #fff;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.8rem;
            margin: 0;
        }

        p {
            color: #fff;
            font-size: 1.15rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.75rem;
            max-width: 30rem;
            margin: 0;
            padding-top: 0.875rem;
            max-width: 35rem;
        }
    }

    .form-area {
        text-align: center;
        margin: 0 auto;
        background: #fff;
        padding: 1.75rem 1.5rem;
        border-radius: 0.5rem;

        img {
            height: auto;
            width: 20%;
        }

        .form-heading {
            //font-family: 'Inter';
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.625rem;
            color: #212B36;
        }

        .forgot-box {
            color: #637381;
            //font-family: 'Inter';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.875rem;
            /* 116.667% */
            text-decoration: none;
        }

        .go-back {
            color: #002145;
            text-align: center;
            /* 14 Medium */
            //font-family: 'Inter';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
            text-decoration: none;
        }

        .login-btn {
            border-radius: 0.3125rem;
            background: #14452f;
            border: none;

            span {

                color: #FFF;
                text-align: center;
                //font-family: 'Inter';
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.25rem;
                /* 125% */
            }

            &:hover {
                background: #1d6444;
                border: 0.5px solid #1d6444;
            }
        }
    }
}