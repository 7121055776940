@media only screen and (max-width: 767px) {
    .p-sidebar {
        width: 100% !important;
    }

    .dashboard-body {
        .card-header {
            display: unset;
            padding: 0px;

            .card-title {
                .searchbar-area {
                    input {
                        max-width: none;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }

            }

            .btn-icon-text-action {
                width: 100%;
            }

            .card-actions {
                justify-content: flex-end;
            }
        }

        .card-body {
            padding: 10px 0px;
        }
    }

    .main-body {
        width: 100%;
        margin: 0px;
        padding-inline: 15px;
        text-align: center;

        .header {
            display: block;

            .logo-container {
                text-align: center;
            }

            .connect-btn {
                width: 100%;
                margin-top: 10px;
            }
        }

        .hero-section {
            margin-block: 25px;

            .image img {
                width: 100%;
                height: auto;
            }

            .serchbtn-container {
                width: 100%;
                display: block;
                text-align: -webkit-center;

                .icon-container {
                    margin-left: 0px;
                    margin-top: 15px;
                }

            }
        }

        .about-section {

            .image {
             img {
                width: 100%;
                height: auto;
            }

            .growth-text {
                font-size: 28px;
               margin-top: 25px;
            }

        }


            .Collaboration-text {
                font-size: 28px;
                color: #F3F4F6;
                margin: 0px;

            }

            .title-sub-text {
                width: 100% !important;
                text-align: left;
            }
        }
    }


}