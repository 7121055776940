.user-container {
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    // border-radius: 10px 10px 0px 0px;
    border-radius: 0.5rem;
    padding: 1rem 0.75rem;
    overflow: auto;
    height: 100%;

    .card-title {
        h3 {
            //font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            margin: 0;
            color: #202124;
        }
    }

    .add-btn {
        background: $primaryColor;
        border-radius: 6px;
        border: none;
        //font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        padding: 9px;
        color: #FFFFFF;
        margin-left: auto;

        i {
            font-size: 16px;
            margin-right: 10px;
            font-weight: 500;
        }

        &:hover {
            background-color: $lighPrimaryColor;
        }
    }

    .heading-area {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 8px;
        margin-left: -5px;

        .back-btn {
            rotate: 90deg;
            background: #FFFFFF;
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50px;
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                background: $primaryColor;
                color: #fff;
            }
        }

        
        .add-btn {
            background: $primaryColor;
            border-radius: 6px;
            border: none;
            //font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.01em;
            padding: 10px;
            color: #FFFFFF;
            margin-left: auto;

            i {
                font-size: 16px;
                margin-right: 10px;
                font-weight: 500;
            }

            &:hover {
                background-color: #22B24C;
            }
        }
        h1 {
            //font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            letter-spacing: 0.04em;

            color: #202124;
            margin: 0;
        }
    }

    .form-area {
        background: #FFFFFF;
        border-radius: 10px 10px 0px 0px;
        // padding: 10px 20px;
        height: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // .custom-inputs {
        //     label {
        //         font-size: 14px;
        //         font-weight: 400;
        //         color: #495057;
        //         display: block;
        //         margin-bottom: 7px;
        //     }
        // }

        .select-btn {
            background: #F6FAFC;
            border-radius: 9px;
            padding: 9px 12px;

            .p-radiobutton .p-radiobutton-box {
                height: 20px;
                width: 20px;
            }

            .p-radiobutton .p-radiobutton-box.p-highlight {
                background: $primaryColor;
                border-color: $primaryColor;

            }

            .p-radiobutton-box {
                &:hover {
                    border-color: $primaryColor;
                }
            }

            label {
                //font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.03em;
                color: #9DACBB;
                padding-left: 8px;
                margin-bottom: 0;
            }
        }

        .submit {
            padding-inline: 25px;
            border-radius: 50px;
            //font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
            color: #FFFFFF;
        }

        .add-action {
            display: flex;
            align-items: center;
            gap: 20px;

            a {
                //font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #EE4947;
                margin: 0;
                text-decoration: none;
            }
        }
    }
}