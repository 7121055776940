.teacher-details {
    padding: 20px;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-info {
    flex: 2;
    margin-left: 20px;
}

.cv-section {
    margin-top: 10px;
}

.teacher-details-right {
    text-align: right;
}

.designation,
.assigned-classes,
.subjects-taught {
    margin-bottom: 15px;
}

.form-section {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
}

.p-fluid .p-field {
    margin-bottom: 20px;
}

.form-actions {
    text-align: right;
}

.form-actions .p-button {
    margin-left: 10px;
}

.status-pill {
    display: inline-flex;
    align-items: center;
    /* background-color: #ff0000;  Red background */
    color: white; /* White text color */
    padding: 5px 10px;
    border-radius: 20px; /* Rounded pill shape */
    font-size: 14px;
  }
  
  .status-pill i {
    font-size: 16px; /* Adjust icon size */
    margin-right: 8px; /* Space between icon and text */
  }
  
  .status-pill span {
    font-weight: bold; /* Bold text for "RED" */
  }
  
  .box {
    padding: 8px;
    border-radius: 4px;
    margin-right: 8px;
    color: white;
  }
  
  .subject-teacher-box {
    border: 1px solid blue;
    background-color: #e0f7fa;
    color: blue;
  }
  
  .class-teacher-box {
    border: 1px solid green;
    background-color: #e8f5e9;
    color: green;
  }
  
