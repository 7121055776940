.timetable-card {
    width: 100%;
    background-color: #1a1a1a; /* Dark theme */
    color: #ffffff;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 0;
}

.timetable-card {
    margin: 10px;
    padding: 0;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
}

.card-header-left {
    display: flex;
    align-items: center;
}

.card-header-right {
    display: flex;
    gap: 10px;
}

.card-content {
    padding: 15px; /* Adds spacing inside the content area */
    /* background-color: #f9f9f9; */
}

.timetable {
    width: 100%;
    border-collapse: collapse;
    color: #ffffff;
}

.timetable th, .timetable td {
    border: 1px solid #333333;
    text-align: center;
    padding: 0.5rem;
}

.timetable th {
    background-color: #2b2b2b;
}

.apply-button {
    margin-top: 20px;
    text-align: right;
}
