.timetable-card {
    padding: 1rem;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-header-left label {
    margin-right: 0.5rem;
}

.duration-input,
.interval-input {
    width: 60px;
    margin: 0 0.5rem;
}

.card-content {
    margin-top: 1rem;
}

.table-header {
    text-align: center;
}

.table-header-subtext {
    font-size: 0.85em;
    color: gray;
}

.timetable-cell {
    padding: 0.5rem;
    text-align: left;
}

.class-text,
.subject-text {
    margin: 0.2rem 0;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
}

.dropdown-label {
    font-size: 0.85em;
    color: #555;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.cancel-button {
    margin-right: 1rem;
}

.save-button {
    background-color: #007bff;
    color: white;
}
