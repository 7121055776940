.teacher-master {
    padding: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters {
    display: flex;
    gap: 10px;
}

.search-bar {
    width: 300px;
}

.teacher-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

/* Card */
.teacher-card {
    background: #f1e1e1;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.teacher-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 12px;
}

.subjects {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 8px;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 8px;
}
